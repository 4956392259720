export const DIM_STYLE =
  'fixed inset-0 w-full transition-all ease-in-out z-20 opacity-80 bg-black touch-none'

export const FAB_RIGHT_POSITION =
  'right-[calc((100%_-_768px)_/_2_+_28px)] tablet:right-[24px]'

export const FAB_STYLE = [
  'fixed',
  'flex',
  'h-16 pr-[76px]',
  'z-30',
  FAB_RIGHT_POSITION,
].join(' ')

// 전화 상담하기 버튼 : 하단 위치 (104) + 네비게이션 탭(52)
export const CALL_FAB = 'bottom-[calc(156px_+_env(safe-area-inset-bottom))]'

// 질문 작성하기 버튼 : 하단 위치 (184) + 네비게이션 탭(52)
export const CREATE_DOCUMENT_FAB =
  'bottom-[calc(236px_+_env(safe-area-inset-bottom))]'

// AI 영양사 버튼 : 하단 위치 (264) + 네비게이션 탭(52)
export const ASSISTANT_FAB =
  'bottom-[calc(316px_+_env(safe-area-inset-bottom))]'
