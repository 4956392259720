import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import { HOTLINE_TELEPHONE, URL_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'
import FAButton from '@/v1/FAButton'
import {
  faButtonLayoutStyle,
  faButtonStyleByVariant,
} from '@/v1/FAButton/styles'
import MaterialSymbol from '@/v1/MaterialSymbol'
import {
  ASSISTANT_FAB,
  CALL_FAB,
  CREATE_DOCUMENT_FAB,
  DIM_STYLE,
  FAB_RIGHT_POSITION,
  FAB_STYLE,
} from './consts'

/**
 * 의료팀에게 질문하기와 전화 상담하기 Floating 버튼
 */
const FloatingButtons = ({ onClose }: { onClose: () => void }) => {
  const { isLoggedIn } = useAuth()
  const { push } = useRouter()
  const { handleCreateBoardAuth } = useCreateBoardModal()

  return (
    <>
      <div className={clsx(FAB_STYLE, ASSISTANT_FAB)}>
        <p className="prose-p2 font-medium text-white my-auto text-right">
          AI 영양사에게 질문하기
        </p>
        <FAButton
          data-ga="main_fab_assistant"
          color="tertiary"
          onClick={() => {
            push(isLoggedIn ? URL_PATH.MyAssistant : URL_PATH.Assistant)
            onClose()
          }}
          className="bg-white bottom-[inherit]"
        >
          <MaterialSymbol name="robot" size={32} />
        </FAButton>
      </div>

      <div className={clsx(FAB_STYLE, CREATE_DOCUMENT_FAB)}>
        <p className="prose-p2 font-medium text-white my-auto text-right">
          의료팀에게 질문하기
        </p>
        <FAButton
          data-ga="main_fab_create"
          color="tertiary"
          onClick={() => {
            handleCreateBoardAuth()
            onClose()
          }}
          className="bg-white bottom-[inherit]"
        >
          <MaterialSymbol name="contact_support" size={32} />
        </FAButton>
      </div>

      <div className={clsx(FAB_STYLE, CALL_FAB)}>
        <p className="prose-p2 font-medium text-white my-auto text-right">
          의료팀 핫라인 전화하기 <br />
          {HOTLINE_TELEPHONE}
        </p>
        <a
          data-ga="main_fab_hotline"
          href={`tel:${HOTLINE_TELEPHONE}`}
          className={clsx(
            faButtonLayoutStyle,
            faButtonStyleByVariant['regular'].size['large'],
            faButtonStyleByVariant['regular'].color['tertiary'],
            FAB_RIGHT_POSITION,
            'bg-white',
            'bottom-[inherit]',
          )}
        >
          <MaterialSymbol
            name="call"
            size={32}
            className="pointer-events-none"
          />
        </a>
      </div>
      <FAButton onClick={onClose} className="z-30">
        <MaterialSymbol name="close" size={32} className="fill-white" />
      </FAButton>
      <div onClick={onClose} className={DIM_STYLE} />
    </>
  )
}

export default FloatingButtons
