'use client'

import AppDownloadBannerWrapper from '@/components/AppDownloadBanner/AppDownloadBannerWrapper'
import CreateDocumentFAButton from '@/components/CreateDocumentFAButton'
import { Divider } from '@/components/Divider'
import Footer from '@/components/Footer'
import useSearchModal from '@/hooks/useSearchModal'
import { MainHeader } from '@/v1/Header/MainHeader'
import List from './List'
import Menu from './Menu'
import HotlineNudge from './Nudges/Hotline'

const Main = () => {
  const { openSearchModal } = useSearchModal()

  return (
    <>
      <AppDownloadBannerWrapper>
        <MainHeader onClick={openSearchModal} />
      </AppDownloadBannerWrapper>
      <div className="p-md">
        <Menu />
      </div>
      <Divider className="border-grey-100 border-[5px]" />
      <List />
      <div className="px-md">
        <HotlineNudge />
      </div>
      <Footer />
      <CreateDocumentFAButton />
    </>
  )
}

export default Main
